
/* Emulator application styles */

div.dde-container {
  background-color: black;
  color: cyan;
  padding-top: 5px;
  margin: 0;
  overflow: auto;
}

.dde-emulator-wrap {
  float: left;
  text-align: left;
  width: calc(100% - 75px); /* 75px is the calculated width of .dde-keys, which is floating to the right */
  height: 100%;
}

pre.dde-emulator {
  background-color: black;
  color: cyan;
  font-weight: bold;
  border-width: 0pt;
  font-family: courier;
  word-wrap: normal;
  padding: 0;
  padding-left: 10px;
  border-radius: 0px;
  /* Fixes issue with Bootstrap styles setting bad line height for <pre> */
  line-height: 1.15;
  /* Removes ugly IE scrollbars from several screens */
  -ms-overflow-style: none;
  overflow: auto;
  height: 100%;
  margin: 0;
}

@media (max-height: 700px) {

  div.dde-container-size {
    width: 50em;
    height: 36em;
  }

  pre.dde-emulator {
    font-size: 8pt;
  }

  div.status-text, div.row.div-emulator{
    width: 650px;
  }
}

@media (min-height: 700px) {

  div.dde-container-size {
    width: 60em;
    height: 44em;
  }

  pre.dde-emulator {
    font-size: 10pt;
  }

  div.status-text, div.row.div-emulator{
    width: 780px;
  }
}

@media (min-height: 800px) {
  div.dde-container-size {
    width: 70em;
    height: 53em;
  }

  pre.dde-emulator {
    font-size: 12pt;
  }

  div.status-text, div.row.div-emulator {
    width: 910px;
  }
}

@media (min-height: 900px) {
  div.dde-container-size {
    width: 78em;
    height: 60em;
  }

  pre.dde-emulator {
    font-size: 14pt;
  }

  div.status-text, div.row.div-emulator {
    width: 1014px;
  }
}

@media (min-height: 1000px) {
  div.dde-container-size {
    width: 88em;
    height: 68em;
  }

  pre.dde-emulator {
    font-size: 16pt;
  }

  div.status-text, div.row.div-emulator {
    width: 1144px;
  }
}

@media (min-height: 1100px) {
  div.dde-container-size {
    width: 98em;
    height: 75em;
  }

  pre.dde-emulator {
    font-size: 18pt;
  }

  div.status-text, div.row.div-emulator {
    width: 1274px;
  }
}

.dde-intensified {
  color: white;
}

.dde-hidden {
  background-color: black;
  color: black;
}

.dde-field {
  background-color: #000;
  color:lime;
  border-width: 0pt;
  border: 0;
  border-bottom: solid 1px #555;
  outline: none;
  margin-left: 7px;
  margin-right: -5px;
}

.dde-field::-ms-clear {
    display: none;
}

.dde-field-intensified, .dde-field-hidden {
  color:red;
}

.dde-field-numeric {
  text-align: right;
}

.dde-highlight-blink {
  text-decoration: blink;
}

.dde-highlight-underscore {
  text-decoration: underline;
}

.dde-highlight-rev-video {
  background-color: cyan;
  color: black;
}

.dde-color-blue {
  color: blue;
}

.dde-color-red {
  color: red;
}

.dde-color-pink {
  color: #ffb6c1;
}

.dde-color-green {
  color: lime;
}

.dde-color-turquoise {
  color: #40e0d0;
}

.dde-color-yellow {
  color: yellow;
}

.dde-color-white {
  color: white;
}

.dde-disabled {
  background-color: #f2dede;
}


/* Status message styles */

.btn-info-img {
  background: #5bc0de url(/img/loading.gif) left 5px center no-repeat;
  color: #FFF;
  padding: 6px 25px;
}

.btn-error {
  background: #fb6b5b;
  color: #FFF;
  padding: 6px 12px;
}


/* Printing styles */

div.print-dialog {
  background-color: #CCC;
  overflow-y: scroll;
  height: 100%;
  z-index: 900;
}

div.dde-container-print {
  width: 32em;
  height: 28em;
  margin-bottom: 13px;
  margin-left: 40px;
  border: 3px solid white;
}

div.dde-form-print {
  width: 37em;
  height: 29em;
  margin-bottom: 13px;
  margin-left: 40px;
  border: 3px solid white;
}

div.dde-emulator-print-select {
  cursor: pointer;
}

pre.dde-emulator-print {
  font-size: 8px;
}

div.keypad {
  position: absolute;
  top: 0;
  left: 70%;
}

div.dde-keys {
  border-width: 0pt;
  border-radius: 0px;
  position: absolute;
  right: 0;
  z-index: 5;
}

.key {
  color: #000000;
  background-color: #a6a6a6;
  border-color: #808080;
  width: 65px;
  border-radius: 2px;
  margin: 4px 1px;
}

@media print {
  .dde-container-print-size {
    width: 42em;
    page-break-inside:avoid;
    page-break-after:auto;
    border-style: solid;
    border-color: black;
    height: 32em;
  }

  pre.dde-emulator {
    font-size: 10pt;
  }

  input, textarea {
    font-family: courier;
    font-weight: bold;
  }

}

div.fixed {
  position: fixed;
  z-index: 5;
}

div.print-page {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

/* Fix for old Bootstrap styles, remove when upgraded */
/*button.radius {
  border-radius: 6px;
}*/

/* Fix for old Bootstrap styles, remove when upgraded */
/*button.key {
  border-radius: 6px;
  margin: 4px 1px;
}*/
.div-emulator {
  padding-top: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

div.status-text {
  font-size: 14pt;
  border-radius: 0;
  position: absolute;
  bottom: 11px;
}

.header-color {
  color: #25313e;
}

/* Fullscreen emulator styling */
.fullscreen .dde-container {
  /* 120x100 to match the 6:5 aspect ratio of the emulator */
  width: 120vh;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}

.fullscreen .dde-emulator-wrap {
  width: 100%;
}

.fullscreen div.row.div-emulator {
  width: 100%;
  background-color: black;
}

.fullscreen #screen_status {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  opacity: 0.9;
}

.fullscreen .dde-keys {
  position: fixed;
  top: 0;
  right: 0;
}

.fullscreen .dde-controls {
  display: none;
}

.fullscreen-fallback {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9999999; /* high z-index to ensure fallback covers all elements */
}

.fullscreen-fallback-close {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 10000000; /* overlay button on fallback */
}

.status-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 1px solid green;
  background-color: transparent;
}

.status-enabled .status-indicator {
  background-color: green;
}

.overtype-toggle.status-enabled .overtype-disabled-text,
.overtype-toggle:not(.status-enabled) .overtype-enabled-text {
  display: none;
}

.color-print-toggle.status-enabled .color-print-disabled-text,
.color-print-toggle:not(.status-enabled) .color-print-enabled-text {
  display: none;
}

.color-print-toggle.status-enabled .status-indicator {
  background-image: linear-gradient(to right, #ff0000, #ffff00, #0000ff);
}

.color-print-toggle:not(.status-enabled) .status-indicator {
  border-color: black;
  background-image: linear-gradient(to right, black, white);
}

.print-color pre.dde-emulator,
.print-color div.dde-container,
.print-grayscale pre.dde-emulator,
.print-grayscale div.dde-container {
  background-color: white;
}

.print-color pre.dde-emulator,
.print-color div.dde-container {
  color: darkcyan;
}

.print-grayscale pre.dde-emulator,
.print-grayscale div.dde-container {
  color: #8b8b8b;
}

.print-color .dde-intensified,
.print-grayscale .dde-intensified {
  color: black;
}

.print-color .dde-hidden,
.print-grayscale .dde-hidden {
  background-color: white;
  color: white;
}

.print-color .dde-field,
.print-grayscale .dde-field {
  background-color: white;
  border-bottom-color: #999999;
}

.print-color .dde-field {
  color: darkgreen;
}

.print-grayscale .dde-field {
  color: #646464;
}

.print-color .dde-field-intensified,
.print-color .dde-field-hidden {
  color: darkred;
}

.print-grayscale .dde-field-intensified,
.print-grayscale .dde-field-hidden {
  color: #8b8b8b;
}

.print-color .dde-highlight-rev-video,
.print-grayscale .dde-highlight-rev-video {
  color: black;
}

.print-color .dde-highlight-rev-video {
  background-color: cyan;
}

.print-grayscale .dde-highlight-rev-video {
  background-color: #dddddd;
}

.print-color .dde-color-blue {
  color: darkblue ;
}

.print-color .dde-color-red {
  color: darkred ;
}

.print-color .dde-color-green {
  color: darkgreen ;
}

.print-grayscale .dde-color-blue,
.print-grayscale .dde-color-red,
.print-grayscale .dde-color-green {
  color: #8b8b8b ;
}

.print-color .dde-color-pink {
  color: #c17682;
}

.print-grayscale .dde-color-pink {
  color: #c1c1c1;
}

.print-color .dde-color-turquoise {
  color: #27998d ;
}

.print-grayscale .dde-color-turquoise {
  color: #999999 ;
}

.print-color .dde-color-yellow {
  color: #b2b201;
}

.print-grayscale .dde-color-yellow {
  color: #b2b2b2;
}

.print-grayscale .dde-color-white,
.print-color .dde-color-white {
  color: black;
}

.print-color .dde-disabled {
  background-color: #f2dede;
}

.print-grayscale .dde-disabled {
  background-color: #f2f2f2;
}




/* Main Dorado DDE CSS file */

span.users-available {
  border: 3px solid #000;
  padding: 10px;
  margin: -50px 10px 0 0;
  font-size: 16px;
  color: #000;
}

div.panel-header {
  padding-left: 13px;
}

div.divider {
  display: block;
  width: 100%;
  padding: 0;
  line-height: inherit;
  border: 0;
}

p.copyright {
  color: #B8B8B8;
}

/* Bookmark header and footer css */
li.tabWidth{
  width: 50%;
}

.header .nav-tabs > li.active a,
.header .nav-tabs > li.active a:hover{
  color: #fff;
  background-color: #00c4b0;
}

.nav>li>a:focus, .nav>li>a:hover,
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color: #00c4b0;
  color: #ffffff;
}

.header .nav-tabs > li a:hover{
  background-color: rgba(0, 0, 0, 0.05)
}

.header .nav-tabs > li a{
  color: #717171;
}

.nav-header, .nav-footer {
    color: #717171;
    font-weight: bold;
    font-size: 14px;
}

:-ms-fullscreen {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.bg-blue {
  background-color: #005c99;
}

.bottom-right{
  position: absolute;
  bottom: 0;
  right: 0;
}

